<template>
  <v-row>
    <v-col cols="12">
      <base-card v-if="$store.state.invoice.listInvoice.length > 0">
        <v-alert
          v-show="copyAlert"
          text
          type="info"
        >
          {{ msgjsoncopiado }}
        </v-alert>
        <v-card-title>
          Listado de Facturas Electrónicas
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            hide-details
            label="Buscar"
            single-line
          />
          <v-spacer />
        </v-card-title>
        <br>
        <v-card-text>
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'filas por pagina',
            }"
            :headers="headers"
            :items="$store.state.invoice.listInvoice"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Estado`]="{ item }">
              <v-tooltip
                v-if="item.Estado === 'NO_AUTORIZADO' || item.Estado === 'DEVUELTA'"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="red darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-minus
                  </v-icon>
                </template>
                <span>{{ item.Estado }}</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="item.Estado === 'P' "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="info darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-minus
                  </v-icon>
                </template>
                <span>PENDIENTE</span>
              </v-tooltip>
              <v-tooltip
                v-else
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="success darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-check
                  </v-icon>
                </template>
                <span>{{ item.Estado }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.UrlPDFNotificacion`]="{ item }">
              <v-dialog
                v-if="item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO' "
                max-width="1500"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="red darken-4"
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="getDataFile(item.UrlPDFNotificacion, 'PDF')"
                    v-on="on"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-actions class="justify-end">
                      <v-icon
                        text
                        @click="dialog.value = false"
                      >
                        mdi-close-thick
                      </v-icon>
                    </v-card-actions>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      PDF FACTURA [{{ item.Prefijo }} - {{ item.Consecutivo }}]
                      <v-icon
                        class="pa-4"
                        @click="downloadPDF(item.Prefijo + '-' + item.Consecutivo)"
                      >
                        mdi-download-circle
                      </v-icon>
                    </v-toolbar>
                    <v-card-text>
                      <v-row
                        v-if="progress == true"
                        align-content="center"
                        class="fill-height"
                        justify="center"
                      >
                        <v-col cols="6">
                          <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            Consultando y Visualizando PDF
                          </v-col>
                          <v-progress-linear
                            color="red darken-4"
                            height="6"
                            indeterminate
                            rounded
                          />
                        </v-col>
                      </v-row>
                      <div class="text-h6">
                        <h5 style="text-align: center">
                          <b>{{ msgPdf }}</b>
                        </h5>

                        <pdf
                          v-for="i in numPages"
                          :key="i"
                          ref="myPdfComponent"
                          :page="i"
                          :src="src"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.reprocesarFactura`]="{ item }">
              <v-dialog
                v-if="item.Estado === 'P'"
                max-width="700"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="success darken-4"
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="reprocessInvoice(item)"
                    v-on="on"
                  >
                    mdi-file-move
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      PROCESAMIENTO DE FACTURA [ {{ item.Prefijo }} - {{ item.Consecutivo }} ]
                    </v-toolbar>
                    <v-card-text>
                      <v-row
                        v-if="progress == true"
                        align-content="center"
                        class="fill-height"
                        justify="center"
                      >
                        <v-col cols="6">
                          <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            Reprocesando factura . Por favor espere un momento
                          </v-col>
                          <v-progress-linear
                            color="red darken-4"
                            height="6"
                            indeterminate
                            rounded
                          />
                        </v-col>
                      </v-row>
                      <div class="text-h6">
                        <json-viewer
                          v-if="reponseReprocessData != ''"
                          :value="reponseReprocessData"
                          copyable="true"
                        >
                          <template
                            v-if="progress == false"
                            v-slot:copy
                          >
                            Copiar
                            <v-icon
                              class="mr-2"
                              color="info darken-3"
                              style="cursor: pointer"
                            >
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false;"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.reenviarEmail`]="{ item }">
              <v-dialog
                v-if="item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO'"
                max-width="600"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="orange darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    @click="sendEmail(item)"
                    v-on="on"
                  >
                    mdi-email-fast
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                      ENVIO DE CORREO DE FACTURA [ {{ item.Prefijo }} - {{ item.Consecutivo }} ]

                    </v-toolbar>
                    <v-card-text>
                      <json-viewer
                        v-if="msgResponseEmail !== ''"
                        :value="msgResponseEmail"
                        copyable="true"
                      >
                        <template
                          v-if="progress == false"
                          v-slot:copy
                        >
                          Copiar
                          <v-icon
                            class="mr-2"
                            color="info darken-3"
                            style="cursor: pointer"
                          >
                            mdi-content-copy
                          </v-icon>
                        </template>
                      </json-viewer>
                    </v-card-text>
                    <v-row
                      v-if="progress == true"
                      align-content="center"
                      class="fill-height"
                      justify="center"
                    >
                      <v-col cols="6">
                        <v-col
                          class="text-subtitle-1 text-center"
                          cols="12"
                        >
                          Enviando Correo
                        </v-col>
                        <v-progress-linear
                          color="red darken-4"
                          height="6"
                          indeterminate
                          rounded
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.ClaveAcceso`]="{ item }">
              <v-icon
                v-if="item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO'"
                v-clipboard:copy="item.ClaveAcceso"
                v-clipboard:success="onCopy"
                class="mr-2"
                color="info darken-3"
                style="cursor: pointer"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <template v-slot:[`item.detalleFactura`]="{ item }">
              <router-link
                v-if="item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO'"
                :to="`/app/invoices/reportInvoicesDetail?Prefijo=${item.Prefijo}&Consecutivo=${item.Consecutivo}`"
              >
                <v-icon
                  class="mr-2"
                  color="info darken-3"
                  style="cursor: pointer"
                >
                  mdi-folder-search
                </v-icon>
              </router-link>
            </template>
            <template v-slot:[`item.UrlXMLRecepcion`]="{ item }">
              <v-icon
                v-if="item.Estado !== 'P'"
                class="mr-2"
                color="brown darken-3"
                style="cursor: pointer"
                @click="getDataFile(item.UrlXMLRecepcion, 'UrlXMLRecepcion [' + item.Prefijo + item.Consecutivo + ']')"
              >
                mdi-folder-zip
              </v-icon>
            </template>
            <template v-slot:[`item.UrlXMLAutorizacion`]="{ item }">
              <v-icon
                v-if="item.Estado !== 'P'"
                class="mr-2"
                color="brown darken-3"
                style="cursor: pointer"
                @click="getDataFile(item.UrlXMLAutorizacion, 'UrlXMLAutorizacion [' + item.Prefijo + item.Consecutivo + ']')"
              >
                mdi-folder-zip
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import pdf from 'vue-pdf'
  import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
  import { columnsRoles } from '@/data/columnsRoles'
  import { mapState } from 'vuex'

  export default {
    name: 'ShowInvoices',
    components: {
      pdf,
    },

    data () {
      return {
        progress: false,
        src: '',
        msgPdf: '',
        reponseReprocessData: '',
        numPages: 0,
        page: 1,
        currentPage: 0,
        singleSelect: false,
        selected: [],
        search: '',
        copyAlert: false,
        size: 300,
        msgjsoncopiado: 'Copiado',
        msgResponseEmail: '',
        headers: columnsRoles,
      }
    },
    computed: {
      ...mapState('invoice', [
        'listInvoice',
        'fileBase64',
        'fileName',
        'responseReprocess',
        'responseEmail',
      ]),
    },
    watch: {
      fileBase64 () {
        console.log(this.fileName)
        if (this.fileName === 'PDF') {
          this.msgPdf = ''
          if (this.fileBase64.Datos !== '') {
            this.previewPDF()
          } else {
            this.msgPdf = 'El PDF AUN NO ESTA DISPONIBLE INTENTELO MAS TARDE'
          }
        } else {
          this.downloadFile(this.fileName)
        }
      },
      responseReprocess () {
        this.showReprocess()
      },
      responseEmail () {
        if (this.responseEmail !== false) {
          this.showResponseEmail()
        }
      },
    },
    created () {
      this.getListInvoice()
    },
    methods: {
      showResponseEmail () {
        this.progress = false
        this.msgResponseEmail = this.responseEmail
        this.$store.dispatch('invoice/setResponseEmail', false)
      },
      showReprocess () {
        this.progress = false
        this.reponseReprocessData = this.responseReprocess
      },
      previewPDF () {
        this.progress = false
        const database64 = 'data:application/pdf;base64,' + this.fileBase64.Datos
        this.src = pdf.createLoadingTask({ url: database64, CMapReaderFactory })
        this.src.promise.then((pdf) => {
          this.numPages = pdf.numPages
        })
      },
      onCopy (e) {
        this.generateAlertCopy()
      },
      generateAlertCopy () {
        this.copyAlert = true
        setTimeout(() => {
          this.copyAlert = false
        }, 2000)
      },
      async getListInvoice () {
        await this.$store.dispatch('invoice/getListInvoice')
      },
      async reprocessInvoice (item) {
        this.reponseReprocessData = ''
        this.progress = true
        await this.$store.dispatch('invoice/reprocessInvoice', item)
      },
      async getDataFile (file, name) {
        const datos = { url: file, nameFile: name }
        this.msgPdf = ''
        this.progress = true
        this.src = ''
        await this.$store.dispatch('invoice/getDataFile', datos)
      },
      downloadPDF (name) {
        const linkSource = 'data:application/pdf;base64,' + this.fileBase64.Datos
        const downloadLink = document.createElement('a')
        const fileName = name + '.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      },

      async downloadFile (name) {
        const linkSource = 'data:application/pdf;base64,' + this.fileBase64.Datos
        const downloadLink = document.createElement('a')
        const fileName = name + '.zip'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      },
      async sendEmail (item) {
        this.msgResponseEmail = ''
        this.progress = true
        await this.$store.dispatch('invoice/sendEmail', item)
      },
    },
  }
</script>

<style scoped>
</style>
