export const columnsRoles = [
  { text: 'Punto de venta', value: 'NombreTienda'},
  { text: 'Prefijo', value: 'Prefijo', align: 'center' },
  { text: 'Consecutivo Factura', value: 'Consecutivo', align: 'center' },
  { text: 'Estado', value: 'Estado', align: 'center' },
  { text: 'Fecha Factura', value: 'FechaFactura' },
  { text: 'Fecha Creación', value: 'FechaCreacion' },
  { text: 'Detalle Factura', value: 'detalleFactura', align: 'center' },
  { text: 'ClaveAcceso', value: 'ClaveAcceso', align: 'center' },
  { text: 'PDF', value: 'UrlPDFNotificacion', align: 'center' },
  { text: 'Reenvia Factura', value: 'reenviarEmail', align: 'center' },

]

if (JSON.parse(atob(localStorage.getItem('uid'))).rol === 'Administrador') {
  columnsRoles.push({ text: 'Reprocesa Factura ', value: 'reprocesarFactura', align: 'center' })
  columnsRoles.push({ text: 'UrlXMLRecepcion', value: 'UrlXMLRecepcion', align: 'center' })
  columnsRoles.push({ text: 'UrlXMLAutorizacion', value: 'UrlXMLAutorizacion', align: 'center' })
}

export const columnsRolesDetail = [
  { text: 'Consecutivo Factura', value: 'ConsecutivoDocumento', align: 'center' },
  { text: 'Estado', value: 'Estado', align: 'center' },
  { text: 'Mensaje Respuesta', value: 'Mensaje', align: 'center' },
  { text: 'Codigo Respuesta', value: 'CodigoRespuesta', align: 'center' },
  { text: 'Fecha Envio', value: 'FechaEnvio' },
  { text: 'Fecha Respuesta', value: 'FechaRespuesta', align: 'center' },
]

if (JSON.parse(atob(localStorage.getItem('uid'))).rol === 'Administrador') {
  columnsRolesDetail.push({ text: 'Xml Hiopos', value: 'UrlXMLHiopos', align: 'center' })
  columnsRolesDetail.push({ text: 'Json Enviado', value: 'Json', align: 'center'})
}
