var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$store.state.invoice.listInvoice.length > 0)?_c('base-card',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.copyAlert),expression:"copyAlert"}],attrs:{"text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.msgjsoncopiado)+" ")]),_c('v-card-title',[_vm._v(" Listado de Facturas Electrónicas "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('br'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            'items-per-page-text': 'filas por pagina',
          },"headers":_vm.headers,"items":_vm.$store.state.invoice.listInvoice,"search":_vm.search},scopedSlots:_vm._u([{key:"item.Estado",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NO_AUTORIZADO' || item.Estado === 'DEVUELTA')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-minus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Estado))])]):(item.Estado === 'P' )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"info darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-minus ")])]}}],null,true)},[_c('span',[_vm._v("PENDIENTE")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Estado))])])]}},{key:"item.UrlPDFNotificacion",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO' )?_c('v-dialog',{attrs:{"max-width":"1500","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"red darken-4"},on:{"click":function($event){return _vm.getDataFile(item.UrlPDFNotificacion, 'PDF')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-icon',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" PDF FACTURA ["+_vm._s(item.Prefijo)+" - "+_vm._s(item.Consecutivo)+"] "),_c('v-icon',{staticClass:"pa-4",on:{"click":function($event){return _vm.downloadPDF(item.Prefijo + '-' + item.Consecutivo)}}},[_vm._v(" mdi-download-circle ")])],1),_c('v-card-text',[(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Consultando y Visualizando PDF ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","height":"6","indeterminate":"","rounded":""}})],1)],1):_vm._e(),_c('div',{staticClass:"text-h6"},[_c('h5',{staticStyle:{"text-align":"center"}},[_c('b',[_vm._v(_vm._s(_vm.msgPdf))])]),_vm._l((_vm.numPages),function(i){return _c('pdf',{key:i,ref:"myPdfComponent",refInFor:true,attrs:{"page":i,"src":_vm.src}})})],2)],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.reprocesarFactura",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'P')?_c('v-dialog',{attrs:{"max-width":"700","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-4"},on:{"click":function($event){return _vm.reprocessInvoice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-move ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" PROCESAMIENTO DE FACTURA [ "+_vm._s(item.Prefijo)+" - "+_vm._s(item.Consecutivo)+" ] ")]),_c('v-card-text',[(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Reprocesando factura . Por favor espere un momento ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","height":"6","indeterminate":"","rounded":""}})],1)],1):_vm._e(),_c('div',{staticClass:"text-h6"},[(_vm.reponseReprocessData != '')?_c('json-viewer',{attrs:{"value":_vm.reponseReprocessData,"copyable":"true"},scopedSlots:_vm._u([(_vm.progress == false)?{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}:null],null,true)}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false;}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.reenviarEmail",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO')?_c('v-dialog',{attrs:{"max-width":"600","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"orange darken-3"},on:{"click":function($event){return _vm.sendEmail(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-fast ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" ENVIO DE CORREO DE FACTURA [ "+_vm._s(item.Prefijo)+" - "+_vm._s(item.Consecutivo)+" ] ")]),_c('v-card-text',[(_vm.msgResponseEmail !== '')?_c('json-viewer',{attrs:{"value":_vm.msgResponseEmail,"copyable":"true"},scopedSlots:_vm._u([(_vm.progress == false)?{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}:null],null,true)}):_vm._e()],1),(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Enviando Correo ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","height":"6","indeterminate":"","rounded":""}})],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.ClaveAcceso",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO')?_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.ClaveAcceso),expression:"item.ClaveAcceso",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")]):_vm._e()]}},{key:"item.detalleFactura",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NOTIFICADO' || item.Estado === 'AUTORIZADO')?_c('router-link',{attrs:{"to":("/app/invoices/reportInvoicesDetail?Prefijo=" + (item.Prefijo) + "&Consecutivo=" + (item.Consecutivo))}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-folder-search ")])],1):_vm._e()]}},{key:"item.UrlXMLRecepcion",fn:function(ref){
          var item = ref.item;
return [(item.Estado !== 'P')?_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"brown darken-3"},on:{"click":function($event){return _vm.getDataFile(item.UrlXMLRecepcion, 'UrlXMLRecepcion [' + item.Prefijo + item.Consecutivo + ']')}}},[_vm._v(" mdi-folder-zip ")]):_vm._e()]}},{key:"item.UrlXMLAutorizacion",fn:function(ref){
          var item = ref.item;
return [(item.Estado !== 'P')?_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"brown darken-3"},on:{"click":function($event){return _vm.getDataFile(item.UrlXMLAutorizacion, 'UrlXMLAutorizacion [' + item.Prefijo + item.Consecutivo + ']')}}},[_vm._v(" mdi-folder-zip ")]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }